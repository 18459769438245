// USB event loop.
// Responsible for triggering claude:usb and claude:race events.

/* global
    AUDEARA_MESSAGE_CODE_EVENT_NAME_MAP,
    HEARING_TEST_STATUS_TYPE_EVENT_NAME_MAP,
    OTHER_MESSAGE_ID_EVENT_NAME_MAP,
    PING_MESSAGE,
    PING_RESPONSE_EVENT,
    RACE_MESSAGE_CODE_EVENT_NAME_MAP,
    RACE_MESSAGE_ID_EVENT_NAME_MAP,
    USB_RESPONSE_CODE_EVENT_NAME_MAP,
*/

const MAX_BYTES_TO_READ = 1024;

function buf2hex(buffer) {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("");
}

function dispatchClaudeUsbEvent(event) {
  console.debug("Dispatching event", event);
  document.dispatchEvent(event);
  document
    .querySelectorAll(
      `[data-claude-usb-serial-number=${event.detail.device.serialNumber}]`,
    )
    .forEach((element) => {
      element.dispatchEvent(event);
    });
}

function startReadUsbEventLoop(device, receiveEndpoint, transmitEndpoint) {
  console.debug("at start of USB event loop");
  device
    .transferIn(receiveEndpoint.endpointNumber, MAX_BYTES_TO_READ)
    .then((message) => {
      startReadUsbEventLoop(device, receiveEndpoint, transmitEndpoint);
      if (message.status == "stall") {
        throw new Error('Device sent message with status of "stall".');
      }

      const event = new Event("claude:usb:messageReceived");
      event.detail = {
        device: device,
        receiveEndpoint: receiveEndpoint,
        transmitEndpoint: transmitEndpoint,
        message: message,
      };
      dispatchClaudeUsbEvent(event);
    })
    .catch((err) => {
      console.info("Error in USB event loop.", err);
    });
}

document.addEventListener("claude:usb:ready", (event) => {
  const device = event.detail.device;
  const transmitEndpoint = event.detail.transmitEndpoint;

  let awaitingResponse = false;

  async function transmitMessageFromElement(element) {
    const messages = element.dataset.claudeUsbB64messages
      .split(" ")
      .map((base64message) => {
        return Uint8Array.from(atob(base64message), (c) => c.charCodeAt(0));
      });

    if (awaitingResponse) {
      const listener = () => {
        document.removeEventListener("claude:usb:messageReceived", listener);
        document.removeEventListener(
          "claude:usb:responsePromiseTimeout",
          listener,
        );
        transmitMessageFromElement(element);
      };
      document.addEventListener("claude:usb:messageReceived", listener);
      document.addEventListener("claude:usb:responsePromiseTimeout", listener);
      console.debug("Waiting to send messages encoded in element", element);
      return;
    }

    const responsePromise = new Promise((resolve) => {
      let timeout;
      const listener = () => {
        clearTimeout(timeout);
        element.removeEventListener("claude:usb:messageReceived", listener);
        resolve();
      };
      timeout = setTimeout(() => {
        console.warn("Response promise timeout");
        listener();
        awaitingResponse = false;
        document.dispatchEvent(new Event("claude:usb:responsePromiseTimeout"));
      }, 500);
      element.addEventListener("claude:usb:messageReceived", listener);
    });

    awaitingResponse = true;
    console.debug("Sending messages encoded in element", element, messages);
    try {
      for (const message of messages) {
        if (message.byteLength > 64) {
          throw new Error("Message is too long; it should be chunked.");
        }
        await device.transferOut(transmitEndpoint.endpointNumber, message);
      }
    } catch (err) {
      console.info("Error sending data to USB device, retrying.", err);
      transmitMessageFromElement(element);
      awaitingResponse = false;
      return;
    }
    await responsePromise;
    awaitingResponse = false;
  }

  function setUpTransmitMessageFromElement(element) {
    if (
      element.dataset &&
      element.dataset.claudeUsbSerialNumber == device.serialNumber &&
      "claudeUsbB64messages" in element.dataset
    ) {
      if ("claudeUsbMessageTrigger" in element.dataset) {
        const windowEvents = ["keyup", "keydown", "keypress"];
        for (const eventName of element.dataset.claudeUsbMessageTrigger.split(
          " ",
        )) {
          if (windowEvents.includes(eventName)) {
            window.addEventListener(eventName, () => {
              transmitMessageFromElement(element);
            });
          } else {
            element.addEventListener(eventName, () => {
              transmitMessageFromElement(element);
            });
          }
        }
      } else {
        transmitMessageFromElement(element);
      }
    }
  }

  document.addEventListener("htmx:afterSettle", (event) => {
    setUpTransmitMessageFromElement(event.target);
    event.target
      .querySelectorAll(
        `[data-claude-usb-serial-number=${device.serialNumber}][data-claude-usb-b64messages]`,
      )
      .forEach((element) => {
        setUpTransmitMessageFromElement(element);
      });
  });

  document
    .querySelectorAll(
      `[data-claude-usb-serial-number=${device.serialNumber}][data-claude-usb-b64messages]`,
    )
    .forEach((element) => {
      setUpTransmitMessageFromElement(element);
    });
});

function messageLengthOk(event, expectedMessageLength) {
  const actualMessageLength = event.detail.message.data.byteLength;
  if (actualMessageLength > expectedMessageLength) {
    console.info(
      `Message length (${actualMessageLength}) does not match expected message length (${expectedMessageLength}). Dispatching extra message event with extra data.`,
    );

    const thisMessageEvent = new Event("claude:usb:messageReceived");
    thisMessageEvent.detail = {
      device: event.detail.device,
      receiveEndpoint: event.detail.receiveEndpoint,
      transmitEndpoint: event.detail.transmitEndpoint,
      message: {
        status: event.detail.message.status,
        data: new DataView(
          event.detail.message.data.buffer.slice(0, expectedMessageLength),
        ),
      },
    };
    if (
      thisMessageEvent.detail.message.data.byteLength != expectedMessageLength
    ) {
      throw new Error(
        `Created event with message ${thisMessageEvent.detail.message.data.byteLength} bytes long, expected ${expectedMessageLength} bytes`,
      );
    }
    dispatchClaudeUsbEvent(thisMessageEvent);

    const extraMessageEvent = new Event("claude:usb:messageReceived");
    extraMessageEvent.detail = {
      device: event.detail.device,
      receiveEndpoint: event.detail.receiveEndpoint,
      transmitEndpoint: event.detail.transmitEndpoint,
      message: {
        status: event.detail.message.status,
        data: new DataView(
          event.detail.message.data.buffer.slice(expectedMessageLength),
        ),
      },
    };
    if (extraMessageEvent.detail.message.data.byteLength == 0) {
      throw new Error("Attempted to send empty extra message");
    }
    dispatchClaudeUsbEvent(extraMessageEvent);

    return false;
  }
  if (actualMessageLength < expectedMessageLength) {
    console.info(
      `Message length (${actualMessageLength}) does not match expected message length (${expectedMessageLength}). Will combine with next message when next message is received.`,
    );

    event.target.addEventListener(
      "claude:usb:messageReceived",
      (event2) => {
        let combinedData = new Uint8Array(
          event.detail.message.data.byteLength +
            event2.detail.message.data.byteLength,
        );
        combinedData.set(new Uint8Array(event.detail.message.data.buffer), 0);
        combinedData.set(
          new Uint8Array(event2.detail.message.data.buffer),
          event.detail.message.data.byteLength,
        );
        const combinedEvent = new Event("claude:usb:messageReceived");
        combinedEvent.detail = {
          device: event.detail.device,
          receiveEndpoint: event.detail.receiveEndpoint,
          transmitEndpoint: event.detail.transmitEndpoint,
          message: {
            status: event.detail.message.status,
            data: new DataView(combinedData.buffer),
          },
        };
        dispatchClaudeUsbEvent(combinedEvent);
      },
      { once: true },
    );
    return false;
  }
  return true;
}

document.addEventListener("claude:usb:messageReceived", (event) => {
  if (event.detail.message.data.byteLength == 0) {
    throw new Error("Received empty message");
  }
  const responseCode = event.detail.message.data.getUint8(0);
  const eventName = USB_RESPONSE_CODE_EVENT_NAME_MAP[responseCode];
  if (!eventName) {
    throw new Error(
      `Unrecognised USB response code 0x${responseCode.toString(
        16,
      )} in message ${buf2hex(event.detail.message.data.buffer)}`,
    );
  }

  const namedEvent = new Event("claude:usb:" + eventName);
  namedEvent.detail = event.detail;
  dispatchClaudeUsbEvent(namedEvent);
});

document.addEventListener("claude:usb:haveRaceFrame", (event) => {
  if (
    messageLengthOk(event, event.detail.message.data.getUint16(3, true) + 5)
  ) {
    const raceMessageCode = event.detail.message.data.getUint8(2);
    const eventName = RACE_MESSAGE_CODE_EVENT_NAME_MAP[raceMessageCode];
    if (!eventName) {
      throw new Error(
        `Unrecognised Race message code 0x${raceMessageCode.toString(
          16,
        )} in message ${buf2hex(event.detail.message.data.buffer)}`,
      );
    }
    const raceEvent = new Event("claude:race:" + eventName);
    raceEvent.detail = event.detail;
    dispatchClaudeUsbEvent(raceEvent);
  }
});

document.addEventListener("claude:race:response", (event) => {
  const raceMessageId = event.detail.message.data.getUint16(5);
  const eventName = RACE_MESSAGE_ID_EVENT_NAME_MAP[raceMessageId];
  if (!eventName) {
    throw new Error(
      `Unrecognised Race message ID 0x${raceMessageId.toString(
        16,
      )} in message ${buf2hex(event.detail.message.data.buffer)}`,
    );
  }
  const raceEvent = new Event("claude:race:response:" + eventName);
  raceEvent.detail = event.detail;
  dispatchClaudeUsbEvent(raceEvent);
});

document.addEventListener("claude:race:notification", (event) => {
  const raceMessageId = event.detail.message.data.getUint16(5);
  const eventName = RACE_MESSAGE_ID_EVENT_NAME_MAP[raceMessageId];
  if (!eventName) {
    throw new Error(
      `Unrecognised Race message ID 0x${raceMessageId.toString(
        16,
      )} in message ${buf2hex(event.detail.message.data.buffer)}`,
    );
  }
  const raceEvent = new Event("claude:race:notification:" + eventName);
  raceEvent.detail = event.detail;
  dispatchClaudeUsbEvent(raceEvent);
});

document.addEventListener(
  "claude:race:response:hearingTestGetStatus",
  (event) => {
    const statusType = event.detail.message.data.getUint8(7);
    const eventName = HEARING_TEST_STATUS_TYPE_EVENT_NAME_MAP[statusType];
    if (!eventName) {
      throw new Error(
        `Unrecognised hearing test status type 0x${statusType.toString(
          16,
        )} in message ${buf2hex(event.detail.message.data.buffer)}`,
      );
    }
    const hearingTestStatusEvent = new Event(
      "claude:race:response:hearingTestGetStatus:" + eventName,
    );
    hearingTestStatusEvent.detail = event.detail;
    dispatchClaudeUsbEvent(hearingTestStatusEvent);
  },
);

document.addEventListener(
  "claude:race:notification:hearingTestGetStatus",
  (event) => {
    const statusType = event.detail.message.data.getUint8(7);
    const eventName = HEARING_TEST_STATUS_TYPE_EVENT_NAME_MAP[statusType];
    if (!eventName) {
      throw new Error(
        `Unrecognised hearing test status type 0x${statusType.toString(
          16,
        )} in message ${buf2hex(event.detail.message.data.buffer)}`,
      );
    }
    const hearingTestStatusEvent = new Event(
      "claude:race:notification:hearingTestGetStatus:" + eventName,
    );
    hearingTestStatusEvent.detail = event.detail;
    dispatchClaudeUsbEvent(hearingTestStatusEvent);
  },
);

document.addEventListener("claude:race:response:other", (event) => {
  const otherMessageId = event.detail.message.data.getUint8(8);
  const eventName = OTHER_MESSAGE_ID_EVENT_NAME_MAP[otherMessageId];
  if (!eventName) {
    throw new Error(
      `Unrecognised Race other message ID 0x${otherMessageId.toString(
        8,
      )} in message ${buf2hex(event.detail.message.data.buffer)}`,
    );
  }
  const otherStatusEvent = new Event("claude:race:response:other:" + eventName);
  otherStatusEvent.detail = event.detail;
  dispatchClaudeUsbEvent(otherStatusEvent);
});

document.addEventListener("claude:race:notification:other", (event) => {
  const otherMessageId = event.detail.message.data.getUint8(8);
  const eventName = OTHER_MESSAGE_ID_EVENT_NAME_MAP[otherMessageId];
  if (!eventName) {
    throw new Error(
      `Unrecognised Race other message ID 0x${otherMessageId.toString(
        8,
      )} in message ${buf2hex(event.detail.message.data.buffer)}`,
    );
  }
  const otherStatusEvent = new Event(
    "claude:race:notification:other:" + eventName,
  );
  otherStatusEvent.detail = event.detail;
  dispatchClaudeUsbEvent(otherStatusEvent);
});

document.addEventListener("claude:usb:haveAuFrame16", (event) => {
  if (
    messageLengthOk(event, event.detail.message.data.getUint16(3, true) + 5)
  ) {
    const audearaMessageCode = event.detail.message.data.getUint16(1);
    const eventName = AUDEARA_MESSAGE_CODE_EVENT_NAME_MAP[audearaMessageCode];
    if (!eventName) {
      throw new Error(
        `Unrecognised Audeara message code 0x${audearaMessageCode.toString(
          16,
        )} in message ${buf2hex(event.detail.message.data.buffer)}`,
      );
    }
    const audearaEvent = new Event("claude:audeara:" + eventName);
    audearaEvent.detail = event.detail;
    dispatchClaudeUsbEvent(audearaEvent);
  }
});

const AUDEARA_INTERFACE_CLASS = 0xff;
const AUDEARA_INTERFACE_SUBCLASS = 0xaa;
const AUDEARA_INTERFACE_PROTOCOL = 0xbf;

window.addEventListener("load", () => {
  if (!navigator.usb) {
    console.info("Browser does not support WebUSB.");
    return;
  }

  navigator.usb.getDevices().then((devices) => {
    if (devices.length == 0) {
      console.info("No devices");
      return;
    }
    devices.forEach((device) => {
      device.open().then(() => {
        console.debug(
          "Device opened, looking for configuration and interface which contains Audeara alternate interface",
        );
        let usbInterface;
        let alternate;
        const configuration = device.configurations.find((configuration) => {
          usbInterface = configuration.interfaces.find((usbInterface) => {
            alternate = usbInterface.alternates.find(
              (alternate) =>
                alternate.interfaceProtocol === AUDEARA_INTERFACE_PROTOCOL &&
                alternate.interfaceSubclass === AUDEARA_INTERFACE_SUBCLASS &&
                alternate.interfaceClass === AUDEARA_INTERFACE_CLASS,
            );
            if (alternate) {
              return usbInterface;
            }
          });
          if (usbInterface) {
            return configuration;
          }
        });

        device
          .selectConfiguration(configuration.configurationValue)
          .then(() => {
            console.debug("Configuration selected");
            device
              .claimInterface(usbInterface.interfaceNumber)
              .then(() => {
                console.debug("Interface claimed");
                device
                  .selectAlternateInterface(
                    usbInterface.interfaceNumber,
                    alternate.alternateSetting,
                  )
                  .then(() => {
                    console.debug("Alternate interface selected");
                    const receiveEndpoint = alternate.endpoints.find(
                      (endpoint) => endpoint.direction === "in",
                    );
                    const transmitEndpoint = alternate.endpoints.find(
                      (endpoint) => endpoint.direction === "out",
                    );
                    startReadUsbEventLoop(
                      device,
                      receiveEndpoint,
                      transmitEndpoint,
                    );

                    const ping_message = Uint8Array.from(
                      atob(PING_MESSAGE),
                      (c) => c.charCodeAt(0),
                    );
                    const pingInterval = setInterval(() => {
                      console.debug("Sending ping message");
                      device
                        .transferOut(
                          transmitEndpoint.endpointNumber,
                          ping_message,
                        )
                        .catch((err) => {
                          console.debug(
                            "Error sending data to USB device during ping, ignoring.",
                            err,
                          );
                        });
                    }, 100);

                    document.addEventListener(
                      PING_RESPONSE_EVENT,
                      () => {
                        console.debug("Received response from ping");
                        clearInterval(pingInterval);

                        const event = new Event("claude:usb:ready");
                        event.detail = {
                          device: device,
                          receiveEndpoint: receiveEndpoint,
                          transmitEndpoint: transmitEndpoint,
                        };
                        dispatchClaudeUsbEvent(event);
                      },
                      { once: true },
                    );
                  });
              })
              .catch((err) => {
                console.info("Error claiming USB interface.", err);

                const template = document.getElementById("message");
                const dialog = template.content
                  .cloneNode(true)
                  .querySelector("dialog");
                const form = dialog.querySelector("form");

                const para1 = document.createElement("p");
                para1.innerText =
                  "There was an error connecting to your USB headphones.";
                dialog.insertBefore(para1, form);

                const para2 = document.createElement("p");
                para2.innerText =
                  "This may be because Audeara Device Cloud (or another site) is connected to them in a different browser tab. If this is the case, please close that tab and reload this page.";
                dialog.insertBefore(para2, form);

                template.parentNode.appendChild(dialog);
                dialog.showModal();
              });
          });
      });
    });
  });
});

window.claudeB64UsbMessage = (event) => {
  let data = new Uint8Array(0);
  if (event && event.detail) {
    data = new Uint8Array(event.detail.message.data.buffer);
  }
  return btoa(String.fromCharCode.apply(null, data));
};

if (navigator.usb) {
  navigator.usb.addEventListener("connect", () => {
    location.reload();
  });
}
